import ContentGuard, { ContentGuardCheck } from "../components/contentGuard"
import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import Loading from "../components/loading"
import Resource from "../components/resources/resource"
import Seo from "../components/seo"
import { useAuth0 } from "@auth0/auth0-react"
import {
	updateInterestGroupNames,
	checkRTDResource,
} from "../components/storyblok/resourcesHelper"

const ResourceItem = ({
	data: { freeResources, resourceInterestGroups, allInterestGroup },
}) => {
	const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0()
	freeResources.interestGroups = resourceInterestGroups?.interestGroups
	const updated = updateInterestGroupNames(
		[freeResources],
		allInterestGroup.nodes
	)
	const isRTDResource = checkRTDResource(updated)

	useEffect(() => {
		if (isLoading) {
			return (
				<div className="text-center flex h-screen justify-center items-center mb-15">
					<Loading />
				</div>
			)
		}
	}, [isLoading])

	const onLoginClicked = () => {
		loginWithRedirect({
			appState: { returnTo: window.location.pathname },
		})
	}

	return (
		<Layout>
			<Seo
				title={`IPWEA Resource - ${freeResources.name}`}
				description={freeResources?.description}
				meta={[
					{
						property: "og:image",
						content: `${freeResources?.thumbnail[0]?.thumbnailUrl}`,
					},
				]}
			/>

			<div className="grid grid-cols-6 gap-4 mx-1 my-2 md:mx-4 md:my-10">
				<aside className="col-span-full sm:col-span-1">
					<RenderAssetInfo resource={freeResources} />
				</aside>
				<article className="col-span-full sm:col-span-5">
					<RenderItem resource={freeResources} />
				</article>
			</div>
			<RenderSignup
				freeResources={freeResources}
				isAuthenticated={isAuthenticated}
				user={user}
				onLoginClicked={onLoginClicked}
				isRTDResource={isRTDResource}
			/>
		</Layout>
	)
}

const RenderSignup = ({
	freeResources,
	isAuthenticated,
	user,
	onLoginClicked,
	isRTDResource,
}) => {
	if (
		ContentGuardCheck(freeResources.contentVisibility, isAuthenticated, user)
		// || (isRTDResource && (!user || !user["https://ipweansw.org/isRTDMember"]))
	) {
		return (
			<>
				{" "}
				{/* {isRTDResource && user && !user["https://ipweansw.org/isRTDMember"] ? (
					<div className="flex justify-center items-center mb-15 mt-5">
						<div className="p-4 border w-1/2">
							<h2 className="text-xl">
								Only Roads & Transport Directorate members can see this content. If you are having trouble accessing this content, please contact, 
								<a href="mailto:rtd@ipweansw.org" className="text-blue-500 underline"> rtd@ipweansw.org</a>.
							</h2>
						</div>
					</div>
				) : ( */}
				<ContentGuard
					loginCallBack={onLoginClicked}
					contentVisibility={freeResources.contentVisibility}
					isRTDResource={isRTDResource}
				/>
				{/* )} */}
			</>
		)
	}
	return (
		<div className="sm:grid gap-4 mx-1 my-2 md:mx-2 md:my-2 crmContent resourceGrid">
			<article className="grid md:col-start-2 md:col-span-4 col-span-full">
				<div dangerouslySetInnerHTML={{ __html: freeResources.description }} />
			</article>
			<div></div>
			{freeResources.fileName && (
				<div className="col-start-2 row-start-2 justify-center md:justify-start mx-1 my-2 md:mx-4 md:my-10 ">
					<div className="">
						<Resource
							resource={freeResources}
							clickToDownload={true}
							useCardStyle={true}
							thumbnailUrl={
								freeResources.thumbnail &&
								freeResources.thumbnail.length > 0 &&
								freeResources.thumbnail[0]?.thumbnailUrl
							}
						/>
					</div>
				</div>
			)}
			<div></div>
		</div>
	)
}

const RenderAssetInfo = ({ resource }) => (
	<dl className="grid grid-cols-1 gap-y-8 justify-center md:justify-start">
		<div className="ml-10">
			<dt className="text-sm font-bold text-gray-500">Authorized On</dt>
			<dd className="mt-1 text-sm text-gray-900">
				{new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "short",
					day: "2-digit",
				}).format(new Date(resource.authorizedOn))}
			</dd>
			<dd className="mt-1 text-sm text-gray-900">{resource.author}</dd>
		</div>
	</dl>
)

const RenderItem = ({ resource }) => (
	<>
		<h1 className="text-4xl pb-4 mb-4 text-neutral font-bold">
			{resource.name}
		</h1>
		<div>{resource.abstract}</div>
	</>
)

export const pageQuery = graphql`
	query ResourceItemQuery($itemId: String!) {
		freeResources(file: { eq: $itemId }) {
			author
			categories
			contentVisibility
			authorizedOn
			description
			file
			fileName
			name
			abstract
			thumbnail {
				thumbnailUrl
			}
		}
		resourceInterestGroups(fileId: { eq: $itemId }) {
			fileId
			interestGroups
		}
		allInterestGroup {
			nodes {
				interestGroupId
				isRTDFilter
			}
		}
	}
`

export default ResourceItem
